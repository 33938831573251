import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { XtrasService } from '../../services/xtras.service';
import { UserModel } from '../../services/user-model';
import { CommonModule } from '@angular/common';
import { ButtonCapYeiComponent } from '../../components/button/button.component';
import { Router } from '@angular/router';
import { SettingsProvider } from '../../services/settings';
import { HttpClientProvider } from '../../services/http-client';
import { LoadingService } from '../../services/loading.service';
import { NotificationService } from '../../services/notification.service';
import { DatatableComponent } from '../../components/datatable/datatable.component';
import { Column, Result } from '../../interfaces/interfaces';
import { MatTableDataSource } from '@angular/material/table';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ProfileSettingsComponent } from '../../components/profile-settings/profile-settings.component';
import { Card, PaymentCardComponent } from '../../components/payment-card/payment-card.component';
import { AddPaymentCardModal } from '../../components/add-payment-card-modal/add-payment-card-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { AlertService } from '../../services/alert.service';
import { Subscription } from 'rxjs';
import { CustomSubjectsService } from '../../services/custom-subjects.service';
import { getEnvironment } from '../../environment/environment';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

@Component({
  selector: 'page-contribution-club',
  templateUrl: './contribution-club.page.html',
  standalone: true,
  imports: [
    CommonModule,
    ButtonCapYeiComponent,
    DatatableComponent,
    LazyLoadImageModule,
    ProfileSettingsComponent,
    PaymentCardComponent,
    ReactiveFormsModule
  ]
})
export class ContributionClubPage implements OnInit, OnDestroy {

  title = 'Hacer un aporte';
  env = getEnvironment();

  totalAmount = '0';

  
  public pagination = null;
  public dataSource = new MatTableDataSource();
  public noData: boolean = false;
  public loadingData: boolean = false;
  public suscriptionList: any = [];

  public columnsSubs: Column[] = [
    { id: 'index',              label: '#',                   hideOrder: 0, width: 80 },
    { id: 'user_donation',      label: 'Nombre completo',     hideOrder: 1 },
    /* { id: 'amount',             label: 'Aporte',              hideOrder: 2 }, */
    
  ];
  
  dataForm:FormGroup = this.fb.group({
    first_name:           new FormControl(null as any, [Validators.required]),
    last_name:            new FormControl(null as any, [Validators.required]),
    hidden_name:          new FormControl(false),
    email:                new FormControl(null as any, [Validators.required, Validators.email]),
    amount:               new FormControl(null, [Validators.required]),
    cellphone:            new FormControl(null),
  });

  constructor(
    private metaTitle: Title,
    public userModel: UserModel,
    private router: Router,
    public xServices: XtrasService,
    private http: HttpClientProvider,
    private load: LoadingService,
    private toast: NotificationService,
    private dialog: MatDialog,
    private alert: AlertService,
    public cSService: CustomSubjectsService,
    public fb: FormBuilder,
  ) {
   
    this.initializeApp();

    
  }

  initializeApp() {

    this.loadingData = true;
    this.noData = false;
    this.pagination = null;
    this.dataSource.data = [];

    this.load.loadingShow();
    this.http.getRequest(SettingsProvider.getUrl(`donation-history`)).subscribe({
      next:(result:any) => {
        //console.log(result);
        if(result['status']) {
          //this.cards = result.data ?? [];
          this.suscriptionList = result['data']['data'] ?? [];
          this.pagination = result['data'];
          this.processData((result['data']['data']).slice(0, 10));
          this.load.dismiss();
          this.loadingData = false;

          //console.log(this.pagination)

          this.totalAmount = this.customFormatNumber(result['others']['total_donations']);
         // console.log(this.totalAmount, result['others'])

        } else {
          this.load.dismiss();
          this.loadingData = false;
          //this.xServices.getErrorApi(result, true, '/', 'Hubo un error al obtener sus tarjetas, intente nuevamente.');
        }
      },
      error: (error: any) => {
        this.load.dismiss();
        this.loadingData = false;
        this.toast.error('¡Error!', 'Hubo un error al conectar con el servidor, verifique su conexión a internet e intente nuevamente.', 4000);
      }
    });
  }
  
  ngAfterViewInit(){
  }

  submit() {
    if(this.dataForm.valid) {
      this.load.loadingShow();

      this.http.postRequest(SettingsProvider.getUrl('donations'), this.dataForm.value).subscribe({
          next: (result: any) => {
            //console.log("****", result);

            if(result['status']&&result['data']) {

              let dataCode = result['data']['url'];
              //console.log(dataCode, SettingsProvider.getUrl(`transaction-by-code/${dataCode}`));
              
              if(dataCode){
                window.open(dataCode);
                //this.payTransaction(dataCode)
              }

              this.dataForm.reset({
                first_name: null,
                last_name: null,
                hidden_name: false,
                email: null,
                amount: null
              });
              this.initializeApp();
              this.toast.success('¡Éxito!', (result['message'] ?? 'Gracias por tu aporte'), 4000);
              this.load.dismiss();
            } else {
              this.load.dismiss();
              this.toast.error('Error al registrarse', (result['message'] ?? 'Los datos introducidos no son correctos.'), 4000);
            }
            
          },
          error: (error: any) => {
            this.load.dismiss();
            this.toast.error('¡Error!', 'Hubo un error al conectar con el servidor, verifique su conexión a internet e intente nuevamente.', 4000);
          }
        });
      
    }
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }

  processData(suscriptions:any) {

    let rows: any[] = [];
    suscriptions.forEach((element:any,  index: number) => {
      element['index'] = index + 1;
      element['action'] = `edit${index + 1}`;
      element['id'] = index + 2;
      let items = {
        ...element,
      };
      rows.push(items);
    });

    this.dataSource.data = rows;
    //console.log(rows);

    if (rows.length > 0) {
      this.noData = false;
    } else {
      this.noData = false;
    }
  }

  goToPaginate(url: any) {
    if (url) {
      this.load.loadingShow();
      this.pagination = null;
       console.log(url, this.pagination);
      this.http.getRequest(SettingsProvider.getCustomUrl(url)).subscribe((result: any) => {
        if (result['status']) {
          // console.log(result['items']);
          this.pagination = result['others'];
          this.suscriptionList = result['data'];
          this.processData(this.suscriptionList);

        } else {

          if (result['message']) {
            this.toast.error('Error al obtener los datos', result['message']);
          } else {
            this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, intente nuevamente.');
          }
        }
        this.load.dismiss();
      }, error => {
        this.load.dismiss();
        this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.');
      });
    }
  }

  payTransaction(id: any) {
    this.load.loadingShow();

    SettingsProvider.getUrl(`transaction-by-code/${id}`)
    return

    this.http.getRequest(SettingsProvider.getUrl(`transaction-by-code/${id}`)).subscribe({
      next:(result:any) => {
        console.log(result);
        
      },
      error: (error: any) => {
        this.load.dismiss();
        this.toast.error('¡Error!', 'Hubo un error al conectar con el servidor, verifique su conexión a internet e intente nuevamente.', 4000);
      }
    });
  }

/* convertir decimales */
  customFormatNumber(number:number) {
    const parts = number.toFixed(2).split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join(',');
  }


}
